<template>


        <div id="ban-1" class="banner-1 text-white" v-if="asset.id!=false" >
            <div class="grid grid-cols-1 grid-central" id="gridCentral">
                <div class="row primeito-titulo" style="color: #0cc4ed; width:100%; font-size: 4vmin; margin-bottom: 2vmin;">
                    Parabéns!
                </div>
                <div class="row primeiro-texto " style="font-size:2vmin;width:60%; margin-bottom: 2vmin;">
                    <span class="text-capitalize" style="padding:0;margin:0;">{{ asset.owner.first_name }} {{ asset.owner.last_name }},</span> 
                    O registro de sua obra está confirmado, 
                    garantindo a proteção de propriedade autoral com extrema segurança.
                </div>

                <div class="row text-lg" style="color: #0cc4ed;width:fit-content; font-size:2.5vmin; margin-bottom: 2vmin; ">
                    Seguem dados do seu registro:
                </div>

                <div class="row" style="font-size:2vmin; margin-bottom: 2vmin;" v-if="asset.name">
                    Música: {{ asset.name }}
                </div>

                <div class="row" style="font-size:2vmin; width:60%; margin-bottom: 2vmin;" v-if="composer">
                    Compositor(es): 
                    <p v-for="(compositor, index) in composer" :key="index" class="text-white" style="margin:0; padding:0;"> {{ compositor }} </p>
                </div>

                <div class="row" style="font-size:2vmin; width:60%; margin-bottom: 2vmin; " v-if="asset.address">
                    Endereço: {{ asset.address }}
                </div>

                <div class="row" style="font-size:2vmin;margin-bottom: 2vmin;">
                    <div class="grid gap-6 grid-cols-2 mb-6" style="width:24em;margin:0;padding:0;" >
                        <div v-if="dataFormatada">
                            Data: {{ dataFormatada }}
                        </div>
                        <div v-if="horaFormatada">
                            Hora: {{ horaFormatada }}
                        </div>
                    </div>
                </div>

                <div class="row" style="font-size:2vmin; margin-bottom: 2vmin;" v-if="asset.hash">
                    Hash ID: 
                   <p class="text-white p-0"> {{ asset.hash }}</p>
                </div>
 
            </div>
        </div>
        
    <div v-else class="text-white text-center mt-10 p-2 " style="border:1px solid #01e769;border-radius:20px;">
        <p class=" text-2xl text-white "> <i class="fas fa-exclamation-triangle text-yellow"></i> Erro 404.</p>
        <span class="text-3xl text-white"> Certificado não encontrado!</span>
        <br>
    </div>

</template>

<script>
import api from '@/api'
import html2pdf from "html2pdf.js";

export default {
    name: 'Certificado',
    data() {
        return {
            asset: false,
            compositores: false,
            address: false,
            dataFormatada: false,
            horaFormatada: false,
        }
    },
    computed: {
        composer() {
            var retorno = false;
            for (let index = 0; index < this.asset.attributes.length; index++) {
                const element = this.asset.attributes[index];
                if(element.trait_type==='composers') {
                    retorno= element.value.split("????")
                }
            }
            return retorno;
        },
    },

    methods: {
        async fetchAsset() {
            if(this.$route.params.id) {
                await api.get(`my-musics/${this.$route.params.id}`).then(response => {
                    this.asset = response
                    // console.log("🚀 ~ file: MusicDetails.vue ~ line 421 ~ awaitapi.get ~ this.asset", JSON.stringify(this.asset))
                }).catch(error => {
                    console.error(error)
                })
            }
        },
        formatarDataHora() {
            const vetor = this.asset.created_at.split(" ")
            this.dataFormatada = vetor[0]
            this.horaFormatada = vetor[1]
        },
        downloadPDF() {
            html2pdf(document.getElementById("ban-2"), {
                image: { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 4, letterRendering: true, useCORS: true },
                filename: "Certificado.pdf",
                jsPDF: { unit: 'em', format: [70,60], orientation: 'p' }
            });
        },


    },
    watch: {
        asset(){
            if(this.asset) {
                // this.getComposers();
                this.formatarDataHora();
            }
        }
    },
    async created() {
        this.fetchAsset();
    },
}
</script>


<style scoped>
@font-face {
    font-family: Nexa;
    src: url('../../../../public/assets/fonts/NexaRegular.woff');
}

* {
    font-family: 'Nexa' !important;
}

.container {
    padding: 0 !important;
}   

@media print {
    .banner-1 {
        width:100%!important;
        padding:0!important;
        margin:0!important;
    }
}


.banner-1 {
    background-image: url('../../../assets/Certificado-registro-musica.png');
    background-color: #191919;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100em;
}

.banner-2 {
    background-image: url('../../../assets/Certificado-registro-musica.png');
    background-color: #191919;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 70em;
}

.grid-central {
    padding-left: 8%;
    padding-top: 40%;
}

.primeiro-texto {
    width:22vmin;
}

p::first-letter {
    text-transform:capitalize;
}

</style>